import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AccountComponent } from './account/account.component';
import { SearchesComponent } from './searches/searches.component';
import { RegistrationsComponent } from './registrations/registrations.component';
import { HelpComponent } from './help/help.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'account', component: AccountComponent },
  { path: 'searches', component: SearchesComponent },
  { path: 'registrations', component: RegistrationsComponent },
  { path: 'help', component: HelpComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
