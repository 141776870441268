<div class="clr-row">
    <form clrForm clrLayout="horizontal">
        <h3>Primary contact</h3>
        <clr-input-container>
            <label>First name</label>
            <input clrInput type="text" placeholder="First name" size="45">
        </clr-input-container>
        <clr-input-container>
            <label>Last Name</label>
            <input clrInput type="password" placeholder="Last Name" size="35">
        </clr-input-container>
        <clr-textarea-container>
            <label>Address</label>
            <textarea clrTextarea rows="5"></textarea>
        </clr-textarea-container>
        <clr-select-container>
            <label>Country</label>
            <select clrSelect>
                <option>United States</option>
                <option>United Kingdom</option>
                <option>Canada</option>
                <option>Switzerland</option>
            </select>
        </clr-select-container>

        <button type="submit" class="btn btn-primary">Update</button>
    </form>
</div>