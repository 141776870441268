<clr-main-container>
  <clr-header class="header-1">
    <div class="branding">
      <a routerLink="/">
        <img src="/assets/artive-logo_full.png" height=65%>
      </a>
    </div>
    <div class="header-nav" [clr-nav-level]="1">
      <!-- <a class="nav-link nav-text" routerLink="/" routerLinkActive="active">Home</a> -->
      <a class="nav-link nav-text" routerLink="/account" routerLinkActive="active">Account</a>
      <a class="nav-link nav-text" routerLink="/searches" routerLinkActive="active">Searches</a>
      <a class="nav-link nav-text" routerLink="/registrations" routerLinkActive="active">Registrations</a>
      <a class="nav-link nav-text" routerLink="/help" routerLinkActive="active">Help</a>
    </div>
    <div class="header-actions">
      <clr-dropdown>
        <button class="nav-text" clrDropdownTrigger>
          Rhynier Myburgh
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a href="#" clrDropdownItem>Preferences</a>
          <a href="#" clrDropdownItem>Log out</a>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </clr-header>
  <div class="content-container">
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
    <nav class="sidenav" [clr-nav-level]="2">
      <section class="sidenav-content">
        <a class="nav-link active" href="#">Details</a>
        <a class="nav-link" href="#">Organization</a>
        <a class="nav-link" href="#">Pricing</a>
      </section>
    </nav>
  </div>
</clr-main-container>